/*
 * @Author: wangxinlie 278019672@qq.com
 * @Date: 2023-07-23 19:02:13
 * @LastEditors: wangxinlie 278019672@qq.com
 * @LastEditTime: 2023-08-01 15:48:07
 * @FilePath: /aurora-carton-web/src/utils/api/procurement/stock.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import request from '../../http'
import {managerUrl} from '../../api'

//仓库列表
export function stockList(data){
  return request({
    url:managerUrl + "/carton/product/stock/info/house",
    method:'post',
    data
  })
}


//入库类型列表接口
export function stockTypeIn(data){
  return request({
    url:managerUrl + "/carton/product/stock/info/type/in",
    method:'post',
    data
  })
}
//出库类型列表接口
export function stockTypeOut(data){
  return request({
    url:managerUrl + "/carton/product/stock/info/type/out",
    method:'post',
    data
  })
}

//仓库列表
export function stockInfoList(data){
  return request({
    url:managerUrl + "/carton/product/stock/info/list",
    method:'post',
    data
  })
}

//仓库启用警用
export function stockInfoUpdate(data){
  return request({
    url:managerUrl + "/carton/product/stock/info/open",
    method:'post',
    data
  })
}

//新增仓库
export function stockInfoAdd(data){
  return request({
    url:managerUrl + "/carton/product/stock/info/add",
    method:'post',
    data
  })
}

//编辑仓库
export function stockInfoEdit(data){
  return request({
    url:managerUrl + "/carton/product/stock/info/edit",
    method:'post',
    data
  })
}