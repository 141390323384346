<template>
  <div class="proBoard" v-loading="loading">
    <board-header quick-search-year quick-search-yesterday v-model:formData="formData"></board-header>
    <div class="boardContent">
      <component :is='item.component' v-model:formData="formData" v-for='item in tabList' v-model:isLike="item.isLike"
                 @like='(e)=>{likeUnit(e,item.unitId)}' :key="item.timer"></component>
    </div>
  </div>
</template>
<script setup>
import BoardHeader from "@components/common/boardHeader/index.vue";
import {getCurrentInstance, ref, shallowRef, watch} from 'vue'
import Trend from './components/trend.vue'
import Board from './components/board.vue'
import GoodsTop5 from './components/goodsTop5.vue'
import SupplierTop5 from './components/supplierTop5.vue'
import InStockWaning from "@views/outStock/board/components/inStockWaning.vue";
import SellOut from "@views/outStock/board/components/sellOut.vue";

import {customAdd, customDel, customList} from '@/utils/api/custom.js'
import {useLoading} from "@utils/tool/loading";

const {proxy} = getCurrentInstance()
const {loading} = useLoading()

const tabList = ref([
  {
    unitId: 103,
    isLike: false,
    component: shallowRef(InStockWaning),
    timer: 0
  },
  {
    unitId: 101,
    isLike: false,
    component: shallowRef(Board),
    timer: 0
  },
  {
    unitId: 102,
    isLike: false,
    component: shallowRef(GoodsTop5),
    timer: 0
  },

  {
    unitId: 104,
    isLike: false,
    component: shallowRef(SupplierTop5),
    timer: 0
  },
  {
    unitId: 105,
    isLike: false,
    component: shallowRef(SellOut),
    timer: 0
  },
  {
    unitId: 100,
    isLike: false,
    component: shallowRef(Trend),
    timer: 0
  },
])

const formData = ref({
  time: [],
  stockId: '',
  timeType: 1,
})

const showLoading = () => {
  tabList.value.forEach(item => {
    item.timer = new Date().getTime() + item.unitId
  })
}
let menuId = 66
const likeUnit = (e, unitId) => {
  if (!e.type) {
    customDel({unitId, menuId}).then(res => {
      if (res.code === 0) {
        tabList.value.forEach(item => {
          if (item.unitId === unitId) {
            item.isLike = false
          }
        })
        proxy.$message.success('取消收藏成功')
      } else {
        proxy.$message.error(res.msg)
      }
    })
  } else {
    customAdd({unitId, menuId}).then(res => {
      if (res.code === 0) {
        tabList.value.forEach(item => {
          if (item.unitId === unitId) {
            item.isLike = true
          }
        })
        proxy.$message.success('收藏成功')
      } else {
        proxy.$message.error(res.msg)
      }
    })
  }
}
const getList = () => {
  customList().then(res => {
    if (res.code === 0) {
      if (res?.data?.list?.length > 0) {
        let list = res.data.list
        tabList.value.forEach(item => {
          list.forEach(i => {
            if (item.unitId === i.unitId) {
              item.isLike = true
            }
          })
        })
      }
    }
  })
}

getList()

//监听formData的变化
watch(
    () => formData.value,
    (val) => {
      showLoading()
    }, {
      deep: true
    }
);
</script>

<script>
export default {
  name: "board",
}
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>